import "./Card.css";

export type card = {
	name: string;
	flipped: boolean;
	resolved: boolean;
	id: number;
};

export default function Card({
	id,
	cards,
	setCards,
	card,
	inputBlocked,
}: {
	id: number;
	card: card;
	cards: card[];
	inputBlocked: boolean;
	setCards: Function;
}) {
	function flip(flipped: boolean) {
		if (inputBlocked === true) return;
		let newCards = cards;
		if (flipped === true) {
			newCards[id].flipped = true;
		} else if (flipped === false) {
			newCards[id].flipped = false;
		}
		setCards([...newCards]);
	}

	return (
		<div
			style={{
				position: "relative",
				justifyContent: "center",
			}}
			className={`memorycard ol-xs-12 col-md-4 ${
				card.resolved ? "resolved" : ""
			}`}
			onClick={() => flip(true)}
		>
			<img
				style={{
					position: "absolute",
					left: "calc(50% - 125px)",
					bottom: 0,
					width: "250px",
				}}
				src="./illustrations/bottom.svg"
				alt=""
			/>
			<img
				style={{
					position: "absolute",
					bottom: "20px",
					left: "calc(50% - 70px)",
					width: "140px",
				}}
				src={`./pictos/${card.name}.svg`}
				alt=""
			/>
			<img
				style={{
					position: "absolute",
					bottom: 0,
					left: "calc(50% - 125px)",
					width: "250px",
					transformOrigin: "bottom",
					transition: "transform 0.5s ease-in-out",
				}}
				className={card.flipped ? "openCard" : ""}
				src="./illustrations/top.svg"
				alt=""
			/>
		</div>
	);
}
