import HealthHeader from "./HealthHeader";
import { useEffect } from "react";
import { useTranslation } from "react-i18next";

export default function Lost({ setState }: { setState: Function }) {
	const { t } = useTranslation();

	useEffect(() => {
		const timeout = setTimeout(() => {
			setState({ state: "screenSaver" });
		}, 60000);
		return () => clearTimeout(timeout);
	}, [setState]);

	return (
		<>
			<HealthHeader></HealthHeader>
			<div className="container">
				<h1 style={{ marginTop: "256px" }}>{t("lost_title")}</h1>
				<p style={{ marginBottom: "48px" }}>{t("lost_text")}</p>
				<sdx-button-group layout="fixed">
					<sdx-button
						label={t("lost_cta")}
						onClick={() => setState({ state: "game" })}
					></sdx-button>
					<sdx-button
						label={t("back")}
						theme="secondary"
						onClick={() => setState({ state: "start" })}
					></sdx-button>
				</sdx-button-group>

				<img
					src="./illustrations/wind.svg"
					alt=""
					style={{
						marginLeft: "auto",
						marginRight: "auto",
						display: "block",
						width: "60%",
						marginTop: "265px",
					}}
				/>
			</div>
		</>
	);
}
