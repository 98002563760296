import "./Bar.css";

export default function Timedisplay() {
	return (
		<div
			style={{ position: "relative", height: "16px", marginBottom: "32px" }}
			className="full-width"
		>
			<div className="bar full-width"></div>
			<div className="bar full-width animated"></div>
		</div>
	);
}
