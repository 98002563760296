import { ReactChild } from "react";
import Timedisplay from "./Timedisplay";
import { useTranslation } from "react-i18next";

export default function HealthHeader({
	children,
	bar,
}: {
	children?: ReactChild;
	bar?: boolean;
}) {
	const { i18n } = useTranslation();

	return (
		<>
			<header
				style={{
					height: "80px",
					display: "flex",
					alignItems: "center",
					justifyContent: "space-between",
				}}
				className="container"
			>
				<img
					src="./Logo_Swisscom_Health.svg"
					alt="Swisscom Health Logo"
					style={{ height: "40px" }}
				></img>
				<div style={{ display: "flex", gap: 16 }}>
					<sdx-select
						onInput={(argument: any) =>
							i18n.changeLanguage(argument.target.value[0])
						}
					>
						<sdx-select-option value="de" selected={i18n.language === "de"}>
							Deutsch
						</sdx-select-option>
						<sdx-select-option value="fr" selected={i18n.language === "fr"}>
							Français{" "}
						</sdx-select-option>
					</sdx-select>

					{children ? children : null}
				</div>
			</header>
			{bar ? (
				<Timedisplay></Timedisplay>
			) : (
				<div
					style={{
						borderBottom: "1px solid #DDE3E7",
						width: "100%",
						marginBottom: "16px",
					}}
				></div>
			)}
		</>
	);
}
