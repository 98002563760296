import { useEffect, useState } from "react";
import Card from "./Card";
import HealthHeader from "./HealthHeader";
import { useTranslation } from "react-i18next";

export type card = {
	name: string;
	flipped: boolean;
	resolved: boolean;
	id: number;
};

export default function Memory({
	state,
	setState,
	campaign,
}: {
	state: { state: string; seconds: number };
	setState: Function;
	campaign: string;
}) {
	const { t, i18n } = useTranslation();
	const [cards, setCards]: [card[], Function] = useState([]);
	const [inputBlocked, blockInput]: [boolean, Function] = useState(false);
	const [startTime]: [number, Function] = useState(Date.now());
	const maxTime = 45000;

	// Check if time is up
	useEffect(() => {
		const interval = setTimeout(() => {
			sendAnalytics(false, campaign);
			setState({ state: "lost" });
		}, startTime + maxTime - Date.now());
		return () => clearInterval(interval);
	}, [setState, startTime, campaign, maxTime]);

	function sendAnalytics(
		success: boolean,
		campaign: string,
		time?: number | string
	) {
		if (!time) time = "";
		const options = {
			method: "POST",
			headers: {
				"Content-Type": "application/json",
			},
			body: `{"success":${success},"time":"${time}","campaign":"${campaign}","language":"${i18n.language}"}`,
		};
		console.log(options);

		fetch("https://health-stats.nico-gartmann.ch/results", options);
	}

	// Generate cards
	useEffect(() => {
		const cardNames: string[] = [
			"ambulance_2_BOLD",
			"Brain_BOLD",
			"hospital-building_BOLD",
			"lungs_BOLD",
			"medicine_BOLD",
			"oxygen-tank_BOLD",
		];

		let cardsTemplate: card[] = [];
		cardNames.forEach((cardName, i) => {
			cardsTemplate.push({
				name: cardName,
				flipped: false,
				resolved: false,
				id: i * 2,
			});
			cardsTemplate.push({
				name: cardName,
				flipped: false,
				resolved: false,
				id: i * 2 + 1,
			});
		});

		function shuffle(array: Array<any>) {
			let currentIndex = array.length,
				randomIndex;
			while (currentIndex !== 0) {
				randomIndex = Math.floor(Math.random() * currentIndex);
				currentIndex--;
				[array[currentIndex], array[randomIndex]] = [
					array[randomIndex],
					array[currentIndex],
				];
			}
			return array;
		}

		cardsTemplate = shuffle(cardsTemplate);

		setCards(cardsTemplate);
	}, [state]);

	// Flip card
	useEffect(() => {
		if (!cards[0]) return;
		const flippedCards = cards.filter(
			(card) => card.flipped === true && card.resolved === false
		);
		if (flippedCards.length === 2) {
			if (flippedCards[0].name === flippedCards[1].name) {
				setTimeout(() => {
					const newCards = cards;
					newCards.find(
						(newCard) => newCard.id === flippedCards[0].id
					)!.resolved = true;
					newCards.find(
						(newCard) => newCard.id === flippedCards[1].id
					)!.resolved = true;
					setCards([...newCards]);
				}, 500);
			} else {
				blockInput(true);
				setTimeout(() => {
					const newCards = cards;
					for (const card of flippedCards) {
						newCards.find((newCard) => newCard.id === card.id)!.flipped = false;
					}
					setCards([...newCards]);
					blockInput(false);
				}, 750);
			}
		}
		const resolvedCards = cards.filter((card) => card.resolved === true);
		if (resolvedCards.length === cards.length) {
			sendAnalytics(true, campaign, Date.now() - startTime);
			setState({ state: "won", seconds: Date.now() - startTime });
		}
	}, [cards, startTime, setState, campaign]);

	return (
		<>
			<title>Memory Game | Swisscom Health</title>

			<HealthHeader bar={true}>
				<sdx-button-group>
					<sdx-button
						theme="secondary"
						label={t("back")}
						onClick={() => setState({ state: "start" })}
					></sdx-button>
				</sdx-button-group>
			</HealthHeader>

			<div className="container" style={{ position: "relative" }}>
				<div className="row">
					{cards[0]
						? cards.map((card, id) => {
								return (
									<Card
										key={card.id}
										id={id}
										cards={cards}
										setCards={setCards}
										card={card}
										inputBlocked={inputBlocked}
									/>
								);
						  })
						: null}
					<div style={{ height: "512px" }}>
						<img
							src="./illustrations/doctor-falling.svg"
							style={{
								height: "512px",
								position: "absolute",
								bottom: "-312px",
								left: "128px",
							}}
							alt=""
						/>
					</div>
				</div>
			</div>
		</>
	);
}
