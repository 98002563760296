import HealthHeader from "./HealthHeader";
import { useTranslation } from "react-i18next";

export default function ScreenSaver({ setState }: { setState: Function }) {
	const { t } = useTranslation();

	return (
		<>
			<HealthHeader></HealthHeader>
			<div className="container">
				<h1 style={{ textAlign: "center", marginTop: "128px" }}>
					{t("screensaver_title")}
				</h1>
				<img
					src="/VR.gif"
					alt="Man playing VR"
					style={{ transform: "translateX(-100px)", marginTop: "-100px" }}
				/>
				<div
					style={{ display: "flex", justifyContent: "center" }}
					className="bigButton"
				>
					<sdx-button
						label={t("screensaver_cta")}
						icon-name="icon-play"
						onClick={() => setState({ state: "start" })}
					></sdx-button>
				</div>
			</div>
		</>
	);
}
