import Memory from "./Memory";
import { useState } from "react";
import Won from "./Won";
import Lost from "./Lost";
import Start from "./Start";
import ScreenSaver from "./screenSaver";
import "./globals.css";

export default function Home() {
	const [state, setstate] = useState({ state: "start", seconds: 0 });
	const [highscore, setHighscore] = useState(45000);
	const [lastPlayed, setLastPlayed] = useState(
		new Date().getUTCFullYear() +
			"-" +
			new Date().getUTCMonth() +
			"-" +
			new Date().getUTCDate()
	);

	const params = new URLSearchParams(window.location.search);
	const [campaign] = useState(
		params.get("campaign") ? params.get("campaign") : ""
	);

	console.log("campaign:", campaign);

	return (
		<>
			<div>
				{state.state === "start" ? (
					<Start setState={setstate}></Start>
				) : state.state === "game" ? (
					<Memory
						state={state}
						setState={setstate}
						campaign={campaign as string}
					/>
				) : state.state === "won" ? (
					<Won
						setState={setstate}
						seconds={state.seconds}
						highscore={highscore}
						setHighscore={setHighscore}
						lastPlayed={lastPlayed}
						setLastPlayed={setLastPlayed}
					></Won>
				) : state.state === "lost" ? (
					<Lost setState={setstate}></Lost>
				) : state.state === "screenSaver" ? (
					<ScreenSaver setState={setstate}></ScreenSaver>
				) : null}
			</div>
		</>
	);
}
