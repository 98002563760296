import HealthHeader from "./HealthHeader";
import { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";

export default function Won({
	seconds,
	setState,
	highscore,
	setHighscore,
	lastPlayed,
	setLastPlayed,
}: {
	seconds: number;
	setState: Function;
	highscore: number;
	setHighscore: Function;
	lastPlayed: string;
	setLastPlayed: Function;
}) {
	useEffect(() => {
		const timeout = setTimeout(() => {
			setState({ state: "screenSaver" });
		}, 60000);
		return () => clearTimeout(timeout);
	}, [setState]);

	const { t } = useTranslation();

	if (
		lastPlayed !==
		new Date().getUTCFullYear() +
			"-" +
			new Date().getUTCMonth() +
			"-" +
			new Date().getUTCDate()
	)
		setLastPlayed(
			new Date().getUTCFullYear() +
				"-" +
				new Date().getUTCMonth() +
				"-" +
				new Date().getUTCDate()
		);

	const [isBestTime, setIsBestTime] = useState(false);
	useEffect(() => {
		console.log(seconds, highscore);
		if (seconds < highscore) {
			setIsBestTime(true);
			setHighscore(seconds);
			console.log("bests");
		}
		console.log(isBestTime);
	}, [highscore, isBestTime, seconds, setHighscore]);

	return (
		<>
			<HealthHeader></HealthHeader>
			<div className="container">
				<h1 style={{ marginTop: "192px" }}>{t("won_title")}</h1>
				<p>
					{t("won_text", { seconds: Math.round(seconds / 100) / 10 })}
					<br />
				</p>
				{isBestTime ? (
					<p>{t("won_best")}</p>
				) : (
					<p>
						{t("won_highscore", { seconds: Math.round(highscore / 100) / 10 })}
					</p>
				)}
				<div
					style={{
						display: "flex",
						gap: "32px",
						alignItems: "center",
						background: "#EEF3F6",
						width: "fit-content",
						padding: "16px 32px",
						borderRadius: "8px",
						marginBottom: "32px",
					}}
				>
					<sdx-icon icon-name="icon-present" size={3} colorClass=""></sdx-icon>
					<p>{t("won_present")}</p>
				</div>
				<sdx-button
					label={t("back")}
					onClick={() => setState({ state: "start" })}
				></sdx-button>
				<img
					src="./illustrations/table.svg"
					alt=""
					className="row"
					style={{
						marginLeft: "auto",
						marginRight: "auto",
						display: "block",
						marginTop: "128px",
					}}
				/>
				<div
					style={{
						position: "absolute",
						bottom: "64px",
						left: "64px",
						background: "#EEF3F6",
						width: "calc(100vw - 128px)",
						padding: "64px",
						display: "flex",
						gap: "128px",
					}}
				>
					<div style={{ width: "384px", background: "white", padding: "32px" }}>
						<img
							src={t("won_qr_path")}
							alt=""
							style={{ width: 320, height: 320 }}
						/>
					</div>
					<div
						style={{
							display: "flex",
							flexDirection: "column",
							justifyContent: "center",
						}}
					>
						<h1>{t("won_qr_title")}</h1>
						<p>{t("won_qr_text")}</p>
					</div>
				</div>
			</div>
		</>
	);
}
